import '@hotwired/turbo-rails';
import { bootstrap } from 'bootstrap';
import { FlashMessages } from '../lib/flash_messages';
import { loadVue } from './load_vue';

const flasher = new FlashMessages();

document.addEventListener('turbo:load', () => {
  loadVue();
  flasher.flash();
});
